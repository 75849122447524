export const gallery: { [key: string]: string[] } = {
  production: ["production/1-1611", "production/1-1612", "/production/1-1616", "production/1-1635", "production/1-1640", "production/1-1660",
  "production/1-1722", "production/1-1746", "production/1-1767", "production/1-5453", "production/1-5456", "production/1-5471", "production/1-5481",
  "production/1-5505", "production/1-5519", "production/1-5530", "production/1-5532", "production/1-5547", "production/1-5571", "production/1-5577", "production/1-5621",
  "production/1-5635", "production/1-5649", "production/1-5653", "production/1-5657", "production/1-5689", "production/1-5709", "production/1-5840",
  "production/1-5844", "production/1-5851", "products/1-1682", "products/1-1688", "products/1-5664", "products/1-5665", "products/1-5679", "products/1-5680", "products/1-5703",
  "products/1-5707", "products/1-5779", "products/1-5797"],
  workspace: ["workspace/1-0320", "workspace/1-0325-B", "workspace/1-5805", "workspace/1-5806", "workspace/1-5814", "workspace/1-5820", "workspace/1-5822",
  "workspace/1-5828", "workspace/1-5831",],
  team: ["team/1-0310", "team/1-0318", "team/1-5751", "team/1-5784"],
}

export const galleryKeys = Object.keys(gallery)
galleryKeys.unshift("all") 
