import * as React from "react"
import "./Progressbar.scss"
import handleViewport from "react-in-viewport"
import { observer } from "mobx-react-lite"
let loadingBar

if (typeof window !== `undefined`) {
  loadingBar = require("@loadingio/loading-bar")
}

interface Props {
  percentage: number
  id?: string
  name: string
  inViewport: any
  forwardedRef: any
}

const Progressbar: React.FC<Props> = observer(
  ({ percentage, id, name, inViewport, forwardedRef }) => {
    React.useEffect(() => {
      if (inViewport) {
        const bar = new loadingBar("#" + id)
        bar.set(percentage)
      }
    }, [inViewport])

    return (
      <div className="skill-bar-container">
        <div className="skill-bar" ref={forwardedRef}>
          <div className="ldBar-container">
            <div
              className="ldBar label-center"
              data-value="0"
              id={id}
              data-type="stroke"
              data-preset="circle"
              data-stroke="data:ldbar/res,gradient(0,1,#FFCA05,#FFAA05)"
              data-stroke-width="10px"
              data-stroke-trail="#F5F5F5"
              data-stroke-trail-width="10px"
              data-stroke-dir="reverse"
            />
          </div>
          <div className="name">{name}</div>
        </div>
      </div>
    )
  }
)

export default handleViewport(Progressbar /** options: {}, config: {} **/)
