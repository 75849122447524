import { SwiperOptions } from "swiper"

export const homeSwiperParams: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 50,
  centeredSlides: true,
  allowTouchMove: false,
}

export const productsSwiperParams: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 50,
  centeredSlides: true,
  loop: true,
  lazy: true,
  autoplay: {
    delay: 5000,
    // disableOnInteraction: true,
  },
  speed: 1000,
  pagination: {
    el: ".swiper-pagination-arrows",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
}
