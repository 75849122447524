import * as React from "react"
import "./Home.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { homeSwiperParams } from "../../config/swiper"
import { observer } from "mobx-react-lite"
import { translations } from "../../translations/translations"
import { images, variables } from "../../data/variables"
import { Link as ScrollLink } from 'react-scroll';
import { store } from "../../translations/store"

const Home = observer(() => {
  return (
    <header className="relative home" id="home">
      <div className="carousel-wrapper absolute w-100 h-100">
        <div className="hero-carousel w-100 h-100">
          <Swiper {...homeSwiperParams}>
            <SwiperSlide className="swiper-slide" id="slide-1" />
          </Swiper>
        </div>
      </div>
      <div className="w-90 h-100" id="home">
        <div className="inner">
          <div className="banner h-100 d-flex">
            <div className="line" />
            <div className="title d-flex flex-column">
              <div className="text">
                <span
                  dangerouslySetInnerHTML={{ __html: translations.home.title }}
                />
                <div className="buttons mt-2">
                  <ScrollLink to='about-us' spy={true} smooth={true}>
                    <button className="button">
                      {translations.home.leftButton}
                    </button>
                  </ScrollLink>
                  <a href={store.shopLink}>
                    <button className="button shopButton ml-2">
                      {translations.home.rightButton}
                    </button>
                  </a>
                </div>
              </div>
              <div className="note">
                <div className="inner d-flex">
                  <div className="left">
                    <span>{translations.home.allFromBees}</span>
                  </div>
                  <div className="right">
                    <img src={images.bee} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
})

export default Home
