import * as React from "react"
import "./Mission.scss"

interface Props {
  title: string
  description: string
}

const Mission: React.FC<Props> = ({ title, description }) => {
  return (
    <div className="mission">
      <div className="inner">
        <div className="title">
          <h3>{title}</h3>
        </div>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}

export default Mission
