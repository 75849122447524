import * as React from 'react'

const useViewport = () =>
{
    const [clientHeight, setClientHeight] = React.useState<number>(0);
    const [clientWidth, setClientWidth] = React.useState<number>(0);

    const w = typeof window !== "undefined" ? window : null;

    React.useEffect(() =>
    {
        const updateWindowDimensions = () =>
        {
            setClientHeight(w.screen.height);
            setClientWidth(w.screen.width);
        }
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, [])
   return [clientHeight, clientWidth];
}

export default useViewport
