import * as React from "react"
import { galleryKeys, gallery } from "../../data/gallery"
import { translations } from "../../translations/translations"
import { imageRoot, images as imageLinks } from "../../data/variables"
import { observer } from "mobx-react-lite"
import "./Gallery.scss";
import handleViewport from "react-in-viewport"

interface Props {
  inViewport: any
  forwardedRef: any
}
const Gallery: React.FC<Props> = observer(({inViewport, forwardedRef}) => {
  const [category, setCategory] = React.useState<string>("all");
  const [image, setImage] = React.useState<number>(0);
  const [autoplay, setAutoplay] = React.useState(true);
  const [firstRenderHappened, setRender] = React.useState(false);
  const [customImageGesture, setCustomImageGesture] = React.useState(false);

  let timeout = null;

  React.useEffect(() =>
  {
    setRender(true);
    // Preload first image for each cat
    Object.keys(gallery).map(key => gallery[key][0]).forEach(img =>
    {
      const preloadedImg = new Image();
      preloadedImg.src = imageRoot + 'gallery/' + img + '.jpg';
    })
  }, []);

  React.useEffect(() => {
    if (inViewport && autoplay)
    {
      const interval = setInterval(() => setImage(prevImg => prevImg === images.length - 1 ? 0 : prevImg + 1), 2500);
      return () => {
        clearInterval(interval);
      }
    }
  }, [inViewport, autoplay]);

  React.useEffect(() => {
    if (customImageGesture) {
      setAutoplay(false);
      timeout = setTimeout(() => setAutoplay(true), 2500); 
      return () => {
        clearTimeout(timeout);
      }
    }

  }, [customImageGesture, image]);
  
  React.useEffect(() =>
  {
    if (firstRenderHappened)
    {
      setAutoplay(false);
      timeout = setTimeout(() => setAutoplay(true), 2500);
      return () =>
      {
        clearTimeout(timeout);
      }
    }
  }, [category]);

  React.useEffect(() => {
    if (typeof Image !== 'undefined')
    {
        // Preload next image
        const nextImg = new Image();
        nextImg.src = imageRoot + 'gallery/' + images[image === images.length - 1 ? 0 : image + 1] + '.jpg';
        // Preload previous image
        const prevImg = new Image();
        prevImg.src = imageRoot + 'gallery/' + images[image == 0 ? images.length - 1 : image - 1] + '.jpg';
    }
  }, [image, category]);

  const imageNumber = image + 1;
  const images = React.useMemo(() => category === "all" ? [].concat.apply([], Object.values(gallery))
    : gallery[category], [category]);

  const img = imageRoot + 'gallery/' + images[image] + '.jpg';

  const handleSetCategory = (cat: string) =>
  {
    setCategory(cat);
    setImage(0);
  }

  const handleChangeImage = (arg: boolean = true) =>
  {
    !arg ? setImage(prevImg => prevImg == 0 ? images.length - 1 : prevImg - 1) : 
      setImage(prevImg => prevImg === images.length - 1 ? 0 : prevImg + 1);
    setCustomImageGesture(true);
  }

  const pagination = () => {
    return (
      <div className="pagination d-flex">
        <div
          className="arrow"
          onClick={() => handleChangeImage(false)}
        >
          <img src={imageLinks.galleryButton} />
        </div>
        <div className="no">
          {imageNumber > 9 ? (
            imageNumber
          ) : (
            <span>
              0<span className="primary-color">{imageNumber}</span>
            </span>
          )}
        </div>
        <div
          className="arrow arrow-right"
          onClick={() => handleChangeImage()}
        >
          <img className="rotate-180" src={imageLinks.galleryButton} />
        </div>
      </div>
    )
  }

  return (
    <div className='gallery-container' ref={forwardedRef}>
      <div className="gallery" id="gallery">
        <div className="inner w-90 d-flex">
          <div className="controls">
            <div className="up">
              <div className="title">
                <h4>{translations.gallery.title}</h4>
              </div>
              <div className="categories">
                <ul>
                  {galleryKeys.map(k => (
                    <li
                      key={k}
                      className={k === category ? "active" : ""}
                      onClick={() => handleSetCategory(k)}
                    >
                      {translations.gallery[k]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {pagination()}
          </div>
          <div
            className="image"
            style={{ background: `url('${img}')` }}
          />
        </div>
      </div>
      <div
        className="mobile-gallery-image"
        style={{ background: `url('${img}')` }}
      />
      <div className="image-name">
        <div className="inner w-90 d-flex">
          <div className='mp-container'>
            <div className="mobile-pagination">{pagination()}</div>
          </div>
          <div className="name">
            <span>{translations.gallery[category]}</span>
            <h4>Delovi za centrifuge</h4>
          </div>
        </div>
      </div>
    </div>
  )
})


export default handleViewport(Gallery /** options: {}, config: {} **/)
