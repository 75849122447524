import * as React from "react"
import { PageProps } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Lazy, Autoplay } from "swiper"
import "./global.scss"
import Home from "../components/Home/Home"
import "./index.scss"
import Mission from "../components/Mission/Mission"
import { translations } from "../translations/translations"
import Progressbar from "../components/Progressbar/Progressbar"
import Assortment from "../components/Assortment/Assortment"
import Footer from "../components/Footer/Footer"
import Accomplishment from "../components/Accomplishment"
import Gallery from "../components/Gallery/Gallery"
import FeaturedProduct from "../components/FeaturedProduct/FeaturedProduct"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import { productsSwiperParams } from "../config/swiper"
import { images, root } from "../data/variables"
import { observer } from "mobx-react-lite"
import TitleWithSeparator from "../components/common/TitleWithSeparator"
import Navbar from "../components/Navigation/Navbar"
import useViewport from "../components/hooks/viewportHook"
import { store } from "../translations/store"
SwiperCore.use([Navigation, Pagination, Lazy, Autoplay])
const img = require('../assets/misija-vizija.png');

const fetchObj = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json'
  }
};

const fetchSuffix = '/wp-json/wc/v3/products?featured=true&attribute=naslovna_opis&attribute=popup_1&attribute=popup_2&consumer_key=ck_0024b71a39362d448217c5931ca5ac9f2833d8c7&consumer_secret=cs_84bcf757f0170736af92815f79249fff87750291';

const Index: React.FC<PageProps> = observer(props => {
  const [swiper, setSwiper] = React.useState(null)
  const [products, setProducts] = React.useState(null);
  const [clientHeight, clientWidth] = useViewport();
  const data = useStaticQuery(graphql`
    query siteTitleQueryAndSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const fetchProducts = React.useCallback(async () => {
    try {
      const promises = await Promise.all([
        fetch('https://evrotom.org/shop' + fetchSuffix, fetchObj),
        fetch('https://evrotom.org/shop/en' + fetchSuffix, fetchObj)
      ]);
      setProducts({
        sr: await promises[0].json(),
        en: await promises[1].json(),
      });
    } catch(err) {
      console.log(err);
    }
  }, []);

  React.useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  // const w = typeof window !== "undefined" ? window : null;
  React.useLayoutEffect(() => {
    if (products) {
      const mySwiper = (document.querySelector(".featured-products .swiper-container") as any).swiper
      setSwiper(mySwiper)
    }
    // if (!store.initialLoad)
    // {
      // const bar = new loadingBar(".loader .ldBar")
      // setTimeout(() => {
      //   document.querySelector(".loader").classList.add("loader--hide")
      //   document.querySelector("#app").classList.remove("stop-scroll")
      // }, 2750)
      // setTimeout(() => {
      //   document.querySelector(".loader").classList.add("d-none")
      // }, 3000)
      // setTimeout(() => bar.set(50), 500)
      // setTimeout(() => bar.set(100), 2000)
      // setTimeout(() => store.setInitialLoad(), 3000)
    // }
  }, [products]);

  React.useEffect(() =>
  {
    const head = document.querySelector('#head');
    const gallery = document.querySelector('.gallery-container');
    if (clientHeight >= 1000 && clientWidth >= 1150)
    {
      head.classList.add('vh100');
    }
    else if (head.classList.contains('vh100'))
    {
      head.classList.remove('vh100');
    }

    if (clientHeight >= 700 && clientWidth >= 861)
    {
      gallery.classList.add('vh100');
    }
    else if (gallery.classList.contains('vh100'))
    {
      gallery.classList.remove('vh100');
    }
  }, [clientHeight, clientWidth])

  const stopAutoplay = () => {
    swiper.autoplay.stop()
  }

  console.log(products);

  return (
    <>
      <SEO 
        title='Evrotom'
        description='Sve za pčele i sve od pčela!'
      />
      {/* {!store.initialLoad && <Loader />} */}
      <div id="app">
        <Navbar />
        <Home />
        <div id='head'>
          <div className="about-us w-90" id="about-us">
            <div className="inner">
              <div className="title text-center">
                <h1>{translations.aboutus.title}</h1>
              </div>
              <div className="about">
                <p
                  dangerouslySetInnerHTML={{ __html: translations.aboutus.about }}
                />
              </div>
            </div>
          </div>
          <div className="missions">
            <div className="inner w-90">
              <div className="mission-list">
                <Mission
                  title={translations.aboutus.missionTitle}
                  description={translations.aboutus.mission}
                />
                <Mission
                  title={translations.aboutus.visionTitle}
                  description={translations.aboutus.vision}
                />
              </div>
            </div>
            <div className='missions-sm-image'>
              <img src={img}/>
            </div>
          </div>
        </div>
        <div className="about-the-company">
          <div className="inner w-90">
            <TitleWithSeparator text="O Evrotomu" />
            <p>
              Naši proizvodi ne samo što su registrovani u pojedinim
              zemljama,već se nalaze na vrhu potraživanja za kvalitete što
              potvrđuje: pehar, veliki broj zlatnih medalja iz Novog Sada,
              Moskve, Beograda, Kijeva, Sofije, Dubaija, Kazablanke, Brisela i
              drugih sajmovai izložbi.
            </p>
            <p className="second">
              Toma Pavlić vlasnik “EVROTOM” DOO je čovek zaljubljen u prirodu i
              sve što dolazi iz prirode. Davnih godina je počeo da se bavi
              pčelarstvom, prvo amaterski da bi to preraslo u njegovu osnovnu
              profesionalnu delatnost. Vrlo brzo je uvideo da na našim terenima
              ne postoje pravi načini ni lekovi kojima je mogao da svoja
              pčelinja društva zaštiti od bolesti i štetočina. Tada su počeli
              prvi eksperimenti. Uporno i vredno, poput pčele radilice,
              sakupljao je znanje i iskustvo da bi vremenom prerastao u iskusnog
              pčelara i vrsnog poznavaoca tajni pčelarstva.
            </p>
          </div>
        </div>
        <div className="skills">
          <div className="w-90 d-flex">
            {skills().map((p, i) => (
              <Progressbar
                key={p.value}
                percentage={p.value}
                name={p.name}
                id={`bar${i}`}
              />
            ))}
          </div>
        </div>
        <div className="assortment" id="assortment">
          <div className="inner w-90">
            <div className="title">
              <h1>NAŠ ASORTIMAN</h1>
            </div>
            <div className="d-flex">
              {assortment().map(a => (
                <Assortment
                  image={a.image}
                  name={a.name}
                  description={a.description}
                  key={a.name}
                  link={a.link}
                />
              ))}
            </div>
            {/* <div className='d-flex mt-2'>
              {assortment.slice(3, 6).map(a =>
                <Assortment
                  image={a.image}
                  name={a.name}
                  description={a.description}
                />
              )}
            </div> */}
          </div>
        </div>
        <div className="accomplishments-container w-90">
          <div className="accomplishments">
            <div className="inner">
              {accomplishments().map(a => (
                <Accomplishment
                  name={a.name}
                  value={a.value}
                  image={a.image}
                  key={a.name}
                />
              ))}
            </div>
          </div>
        </div>
        <Gallery />
        {!!products && <div className="featured-products" onMouseOver={stopAutoplay}>
          <div className="inner">
            <Swiper {...productsSwiperParams}>
              {products?.[store.language]?.map((product) =>
                {
                  const { attributes } = Object(product);
                  return (
                    <SwiperSlide className="swiper-slide" key={product.id}>
                      <FeaturedProduct
                        name={product.name}
                        shortDescription={attributes?.[0]?.options?.join('')}
                        link={product.permalink}
                        image={product.images?.[0]?.src}
                        features={[
                          attributes[1]?.options?.join(''),
                          attributes[2]?.options?.join('')
                        ]}
                      />
                    </SwiperSlide>
                  )
                }
              )}
            </Swiper>
            <div className="swiper-controls">
              <div className="inner w-90 d-flex align-items-center justify-content-center">
                <div className="swiper-prev">
                  <img className="" src={images.productArrow} />
                </div>
                <div className="swiper-pagination-arrows" />
                <div className="swiper-next">
                  <img className="rotate-180" src={images.productArrow} />
                </div>
              </div>
            </div>
          </div>
        </div>}
        <Footer />
      </div>
    </>
  )
})

function skills(): { value: number; name: string }[] {
  return [
    { value: 93, name: translations.aboutus.skills.equipment },
    { value: 90, name: translations.aboutus.skills.tools },
    { value: 83, name: translations.aboutus.skills.medicine },
    { value: 70, name: translations.aboutus.skills.food },
    { value: 100, name: translations.aboutus.skills.protection },
    { value: 91, name: translations.aboutus.skills.products },
  ]
}
function assortment(): { description?: string; name: string; image: string, link: string }[] {
  return [
    {
      name: translations.assortment.equipment.title,
      description: translations.assortment.equipment.description,
      image: images.frame,
      link: root + '/shop/product-category/oprema-za-pcelarstvo/'
    },
    {
      name: translations.assortment.tools.title,
      description: translations.assortment.tools.description,
      image: images.frame2,
      link: root + '/shop/product-category/pribor-za-pcelarstvo/'
    },
    {
      name: translations.assortment.medicine.title,
      description: translations.assortment.medicine.description,
      image: images.frame3,
      link: root + '/shop/product-category/lekovi-i-preparati-za-pcele/'
    },
    {
      name: translations.assortment.food.title,
      description: translations.assortment.food.description,
      image: images.frame4,
      link: root + '/shop/product-category/hrana-za-pcele/'
    },
    {
      name: translations.assortment.protection.title,
      description: translations.assortment.protection.description,
      image: images.frame5,
      link: root + '/shop/product-category/zastitna-oprema/'
    },
    {
      name: translations.assortment.products.title,
      description: translations.assortment.products.description,
      image: images.frame6,
      link: root + '/shop/product-category/pcelinji-proizvodi/'
    },
    {
      name: translations.assortment.frames.title,
      image: images.kosnica,
      link: root + '/shop/product-category/kosnice-i-delovi-za-kosnice/'
    },
    {
      name: translations.assortment.program.title,
      image: images.program,
      link: root + '/shop/product-category/api-fito/'
    },
    {
      name: translations.assortment.cosmetics.title,
      image: images.kozmetika,
      link: root + '/shop/product-category/apina-kozmetika/'
    },
    {
      name: translations.assortment.literature.title,
      image: images.literatura,
      link: root + '/shop/product-category/literatura/'
    },
  ]
}

function accomplishments(): { name: string; image: string; value: number }[] {
  return [
    {
      name: translations.accomplishments.awards,
      image: images.awards,
      value: 59,
    },
    {
      name: translations.accomplishments.clients,
      image: images.handshake,
      value: 1054,
    },
    {
      name: translations.accomplishments.team,
      image: images.team,
      value: 35,
    },
    {
      name: translations.accomplishments.project,
      image: images.bulb,
      value: 154,
    },
  ]
}
export default Index
