import * as React from "react"
import CountUp from "react-countup"
import handleViewport from "react-in-viewport"

interface Props {
  value: number
  image: any
  name: string
  inViewport: any
  forwardedRef: any
}

export const Accomplishment: React.FC<Props> = ({
  name,
  value,
  image,
  inViewport,
  forwardedRef,
}) => {
  const [firstMount, setMounted] = React.useState<boolean>(false)
  const counter = firstMount ? (
    <CountUp end={value} duration={3} useEasing />
  ) : (
    <>0</>
  )
  React.useEffect(() => {
    if (inViewport) {
      setMounted(true)
    }
  }, [inViewport])

  return (
    <div className="item" ref={forwardedRef}>
      <div className="image">
        <img src={image} />
      </div>
      <div className="value">
        <h1>{counter}</h1>
      </div>
      <div className="name">{name}</div>
    </div>
  )
}

export default handleViewport(Accomplishment /** options: {}, config: {} **/)
